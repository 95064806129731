.ifr-input {
    width: 70%;
}

.pass-fields label{
    width: 40% !important;
}

.pass-fields input {
    width: 60% !important;
}

@media screen and (min-width: 992px) {
    .ifr-input {
        width: 50% ;
    }
    .w-lg-75 {
        width: 75%;
    }

    .pass-fields label {
        width: 25% !important;
    }

    .pass-fields input {
        width: 25% !important;
    }
}
