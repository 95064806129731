.login-form-wrapper {
    width: 90%;
    margin: 10% auto;
    border: 1px solid grey;
}

.lf-back-wrapper {
    padding: 2%;
    border-bottom: 1px solid grey;
    text-align: left;
}

.font-09 {
    font-size: 0.9em;
}

.font-color-main {
    color: #666666;
}

@media screen and (min-width: 992px) {
    .login-form-wrapper {
        width: 25%;
    }
}