.order-card-status {
    color: #24303E;
    font-weight: bold;
    width: 85%;
    margin: 0 auto;
}

.rejected-color {
    border-bottom: 4px solid #F80707;
}

.accepted-color {
    border-bottom: 4px solid #1CC88A;
}

.pending-color {
    border-bottom: 4px solid #F6C23E;
}

.delivered-color {
    border-bottom: 4px solid #36B9CC;
}
