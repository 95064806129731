.vp-card-grid-wrapper {
    background: white;
    border-radius: 0.225rem;
    height: 100%;
}

.vp-card-grid-erpid {
    font-size: 0.7rem;
    color: #818085;
    text-align: left;
    padding-left: 5%;
    padding-top: 3%;
}

.vp-card-grid-image-wrapper {
    height: 144px;
}

.vp-card-grid-product-image {
    max-width: 144px;
    max-height: 144px;
}

.vp-card-grid-product-name {
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
    color: #019D7F;
    height: 68px;
    line-height: 1;
    display: table-cell;
    vertical-align: middle;
    padding-top: 2%;
}

.vp-card-grid-product-name-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.vp-card-grid-product-price {
    margin-top: 3%;
    padding-left: 5%;
    text-align: left;
    font-size: 0.725rem;
    color: #76757A;
}

.vp-card-grid-price-pills-wrapper {
    margin-top: 3%;
    padding-left: 2%;
    font-size: 0.75rem;
    color: #707070;
}

.vp-card-grid-availability {
    padding-left: 5%;
    text-align: left;
    font-size: 0.625rem;
    color: #019D7F;
}

.vp-card-grid-price-total {
    color: #005343;
    text-align: right;
    line-height: 1.1;
    height: 30px;
}

.vp-card-grid-price-pills-wrapper {
    line-height: 1;
}

.vp-card-grid-promo-wrapper {
    padding-left: 5%;
    display: flex;
    flex-wrap: wrap;
}

.pc-container {
    width: 50%;
}

.vp-card-grid-price-total {
    padding-right: 6%;
    margin-top: 2%;
}

.card-grid-mt {
    margin-top: 9%;
}

@media screen  and (min-width: 750px) {
    .pc-container {
        width: 25%;
    }
}

@media screen  and (min-width: 1000px) {
    .vp-card-grid-availability {
        margin-top: 2%;
        font-size: 0.7em;
    }

    .pc-container {
        width: 25%;
    }
}

@media screen  and (min-width: 1367px) {
    .pc-container {
        width: 16.66%;
    }
}



