.order-card-wrapper {
    width: 96%;
    background-color: white;
    margin: 2% auto 0;
    padding: 2% 0;
    border-radius: 5px;
}

.order-card-row-2 {
    padding: 0 2%;
    display: flex;
}

.order-card-name {
    width: 60%;
    text-align: left;
    color: #707070;
    font-size: 1.375em;
    font-weight: bold;
}

.order-card-products-count span, .order-card-products-price span {
    font-weight: bold;
}

.order-card-row-3 {
    display: flex;
    padding-bottom: 1%;
}

.order-card-add-btn-wrapper {
    width: 55%;
    padding: 3% 1%;
}

.order-card-add-button {
    background-color: #019D7F;
    border-radius: 50px;
    padding: 3% 6% 2%;
    color: white;
    font-weight: bold;
    font-size: 0.875em;
    cursor: pointer;
    border: none;
}

.order-card-status-wrapper {
    width: 40%;
    padding: 3% 0;
    margin-left: 5%;
}

.order-card-view-order-btn {
    background-color: #F7F5EF;
    color: #019D7F;
    font-weight: bold;
    width: 100%;
    text-align: right;
    padding: 4% 5%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 3px 6px #B0B0B0;
}

.order-card-view-order-btn a {
    color: inherit;
}

.text-grey {
    color: #707070;
}

.order-card-name a:hover {
    text-decoration: none;
    color: inherit;
}

.orders-dd {
    background: url(../../../../assets/chevron-down-solid.svg) no-repeat 95%;
    background-size: 0.8em;
    padding: 1%;
}

@media screen and (min-width: 998px) {
    .order-card-wrapper {
        display: flex;
    }

    .order-card-row-2 {
        width: 40%;
        padding: 0 0 0 1%;
    }

    .order-card-view-order-btn {
        display: none;
    }

    .order-card-name {
        border-right: 1px solid #DBDBDB;
        width: 68%;
    }

    .order-card-status-wrapper {
        width: 50%;
        margin: 0;
        padding-top: 5%;
        border-right: 1px solid #DBDBDB;
    }

    .order-card-row-3 {
        width: 20%;
        border-right: 1px solid #DBDBDB;
    }

    .order-card-add-btn-wrapper {
        width: 85%;
        padding: 8% 1% 0 0;
        margin: 0 auto;
    }

    .order-card-view-order-btn {
        width: 96%;
        margin: 0 auto;
        padding: 1%;
    }
}
