.table-expander[aria-expanded=false] {
    background-image: url(../../../assets/caret-down-white.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.table-expander[aria-expanded=true] {
    background-image: url(../../../assets/caret-up-white.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.row-centered td {
    vertical-align: middle;
}

.input-num-hide-arrows {
    width: 60px;
    -moz-appearance: textfield;
}

.change-category-text:hover {
    color: #707070;
    font-weight: bold;
    cursor: pointer;
}

.change-category-list {
    max-height: 60vh;
    overflow-y: scroll;
}

.current-selected-cat {
    color: #707070;
    font-weight: bold;
    text-decoration: underline;
}