.vp-search-wrapper {
    background: white;
    border: 1px solid #717171;
    box-shadow: 0 0 6px;
    border-radius: 5px
}

.vp-search {
    width: 100%;
    display: flex;
    height: inherit;
}

.vp-search-input {
    width: 90%;
    border: none;
    background: none;
    padding-left: 0.8em;
}

.vp-search img {
    padding-left: 0.8em;
}

.vp-search-input::placeholder {
    font-size: 0.925rem;
    background: none;
}

.vp-search-input:focus {
    outline: none;
}
