.vp-expandable-content {
    background: white;
}
.vp-expand-info-wrapper[aria-expanded=false] {
    background: url(../../../assets/chevron-down-solid.svg) no-repeat right 5% top 50% !important;

}

.vp-expand-info-wrapper[aria-expanded=true] {
    background: url(../../../assets/chevron-up-solid.svg) no-repeat right 5% top 50%;
    background-color: white;

}

.vp-expand-info-wrapper {
    border-bottom: 2px solid white;

}

.vp-expandable-text {
    color: #707070;
    font-weight: bold;
}

.vp-expand-info-wrapper.collapsed{
    background: #F7F5EF;
}