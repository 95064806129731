.ov-rating-title-wrapper {
    width: 100%;
}

.ov-rating-title {
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    padding-top: 5%;
}

.rate-separator {
    width: 30%;
    margin: 4% auto ;
    border-top: 2px solid #fff;
}

.ov-rate-criteria {
    color: #fff;
    padding: 3% 0;
    font-size: 1.1em;
}

.sticky-rate {
    position: fixed;
    top: 0;
    margin-top: 0;
    width: 27%;
    z-index: 10;
}