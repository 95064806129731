.vp-units-pill {
    display: inline-block;
    margin-right: 3%;
    font-size: 0.825rem;
    color: #707070;
    border: 0.8px solid #717171;
    border-radius: 5px;
    padding: 4px 10px;
    text-align: center;
    cursor: pointer;
}

.active {
    background: #019D7F33;
    color: #707070;
}

@media screen and (min-width: 1023px) {

    .vp-units-pill {
        font-size: 1rem;
    }

}