.vrp-action-buttons-wrapper > div > .vov-icons-wrapper {
    width: 100% !important;
}

@media screen and (max-width: 786px) {
    .vrp-action-buttons-wrapper > div > .vov-icons-wrapper > button > div {

        padding: 0 10% !important;
    }

    .vrp-action-buttons-wrapper > div > .vov-icons-wrapper {
        text-align: center !important;
    }
}