.all-orders-container {
    background-color: #F7F5EF !important;
}

.op-status-wrapper {
    width: 100%;
}

.op-orders-wrapper {
    width: 100%;
}

.all-orders-body {
    min-height: 100vh;
}

@media screen and (min-width: 998px){
    .op-status-wrapper {
        display: flex;
        justify-content: flex-end;
    }
}
