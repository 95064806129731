.sc-delete-wrapper {
    width: 30%;
    padding: 8% 3% 3% 10%;
    line-height: 1;
    font-size: 0.75em;
    font-weight: bold;
}

.sc-delete-text {
    text-transform: lowercase;
}

.sc-delete-btn {
    cursor: pointer;
}

.trash-icon {
    width: 1em;
}