.vp-vat-price-label {
    color: #005343;
    font-size: 0.6875rem;
    font-weight: bold;
}

.vp-vat-price {
    font-size: 0.6875rem;
    color: #005343;
    font-weight: bold;
}

.vp-no-vat-price-label {
    font-size: 0.625rem;
    color: #427F73;
}

.vp-no-vat-price {
    font-size: 0.625rem;
    color: #427F73;
}




@media screen  and (min-width: 1000px) {

    .vp-no-vat-price {
        color: #005343CC;
        font-size: 0.825rem;
        line-height: 2;
    }

}


@media screen and (min-width: 1366px) {

    .vp-no-vat-price-label {
        font-size: 0.9rem;
    }

    .vp-vat-price-label {
        font-size: 0.9rem;
    }

    .vp-vat-price {
        font-size: 0.825rem;
    }

    .vp-no-vat-price {
        font-size: 0.825rem;
    }

}



