.order-body-wrapper {
    background-color: #F7F5EF;
    min-height: 75vh;
}

.ov-order-total-section {
    background-color: white;
    border-radius: 5px;
}

.ov-order-total-section > div > .sc-order-total-heading {
    color: #707070;
}

.ov-order-total-section > div > div > .sc-order-total-price, .sc-order-vat {
    color: #707070;
}

.ov-products-header-wrapper {
    padding: 4% 0 0;
}

.ov-products-header-name {
    text-align: left;
    font-weight: bold;
    padding: 5% 5% 0;
}

.ov-products-header-cells {
    display: flex;
    width: 100%;
}

.ov-measure-unit {
    padding: 3% 2%;
    width: 25%;
}

.ov-single-vat-price {
    padding: 3% 4%;
    width: 25%;
}

.ov-quantity {
    width: 20%;
    padding: 3% 2%;
}

.ov-price-vat {
    width: 30%;
    padding: 3% 2%;
    text-align: right;
}

.bordered-right {
    border-right: 1px solid #d8d8d8;
}

.bordered-left {
    border-left: 1px solid #d8d8d8;
}

.ov-header-wrapper > div {
    padding-bottom: 0;
}

.ov-add-btn-bottom-wrapper {
    padding: 5%;
}

.ov-pos-label {
    opacity: 59%;
    text-align: left;
    padding-left: 5%;
}

.ov-pos-name {
    text-align: left;
    padding-left: 5%;
    color: #707070;
    font-size: 1.2em;
}

.ov-custom-product-wrapper {
    width: 96%;
    margin: 0 auto;
}

.ov-confirm-button-wrapper {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #707070;
    padding: 5% 3%;
}

.ov-delivery-confirm-wrapper {
    width: 100%;
    background-color: #707070;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    padding-bottom: 10%;
}


@media screen and (min-width: 998px) {
    .side-wrapper {
        width: 38%;
        margin: 6% 0 0 2%;
        float: left;
    }

    #rate-to-stick {
        position: sticky;
        top: 12%;
        z-index: 50;
    }

    .ov-order-total-section {
        width: 100%;
        position: relative;
    }

    .ov-delivery-confirm-wrapper {
        float: left;
        width: 100%;
        background-color: #707070;
        display: block;
        margin-top: 2%;
        border-radius: 5px;
        margin-bottom: 2%;
        padding-bottom: 10%;
    }

    .ov-products-header-wrapper {
        display: flex;
        width: 100%;
        padding: 0 0 0 2%;
    }

    .ov-products-header-name {
        padding: 4% 0 0 1%;
        width: 39%;
        font-size: 1.2em;
    }

    .ov-products-header-cells {
        width: 65%;
        padding-top: 2%;
    }

    .bordered-left, .bordered-right {
        border: none;
    }

    .ov-measure-unit {
        border-right: 1px solid #d8d8d8;
        width: 16%;
    }

    .ov-quantity, .ov-price-vat, .ov-measure-unit {
        border-left: 1px solid #d8d8d8;
    }

    .ov-price-vat {
        padding-right: 6%;
    }


    .ov-single-vat-price {
        padding-top: 3%;
        width: 28%;
    }

    .ov-quantity {
        width: 15.5%;
    }

    .ov-add-btn-bottom-wrapper {
        width: 35%;
        float: right;
    }
}