.vp-logo {
    max-width: 75px;
    max-height: 75px;
}

.vp-intro {
    z-index: +999;
    padding-bottom: 5%;
    background: white;
    box-shadow: 0px 5px 5px 0px #d1d1d1;
}

.vp-name {
    font-weight: bold;
    color: black;
    font-size: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vp-completed-orders {
    font-size: 0.8em;
}

.vp-rate {
    font-size: 0.825rem;
    padding-top: 1%;
    padding-bottom: 1%;
}

.vp-name-wrapper {
    margin-top: 3%;
}

.vp-minimal-order {
    font-size: 0.75rem;
    line-height: 1;
    font-weight: bold;
    max-width: 27vw;
}

@media screen and (min-width: 998px) {
    .vp-intro {
        padding-bottom: 1%;
    }

    .vp-name-wrapper {
        margin-top: 2%;
    }

    .vp-name {
        font-size: 1.5rem;
        color: #707070;
    }

    .vp-logo-wrapper {
      height: inherit;
    }

    .vp-logo {
        margin: auto;
        max-height: 100%;
        max-width: 144px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media screen and (max-width: 359px) {
    .vp-name {
        height: 3vh;
        font-size: 0.9em;
    }

    .vp-rate {
        font-size: 0.625rem;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .vp-completed-orders {
        font-size: 0.625rem;
    }

    .vp-minimal-order div {
        padding-left: 0 !important;
        font-size: 0.9em;
    }
}

@media screen and (min-width: 1500px){
    .vp-intro-index {
        margin-top: 0.3%;
    }
}


@media screen and (max-width: 320px) {
    .vp-logo-wrapper {
        margin-right: 3%;
    }

    .vp-name {
        height: 3vh;
    }
}







