.np-link-btn {
    color: white;
    font-weight: bold;
    width: 100vw;
}

.np-link-btn:hover {
    text-decoration: none;
    color: white;
}

.np-link-btn-text {
    margin-top: 2%;
    background-color: #019D7F;
    width: 100%;
    border-radius: 5px;
    line-height: 2;
    text-align: center;
    text-transform: uppercase;
}
