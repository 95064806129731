.vp-cart-list-input-num-wrapper {
    width: 50%;
}

.cart-input-num {
    width: 100%;
    border: none;
    background: none;
    -moz-appearance: textfield;
}

.cart-input-num::-webkit-outer-spin-button,
.cart-input-num::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cart-input-num:focus {
    outline: none;
}

.vp-up-down-btn {
    background: white;
    border: 1px solid #0f6848;
    border-radius: 50px;
    width: 100%;
    height: 30px;
}

.sign-wrapper {
    font-size: 1.4em;
    background: none;
    border: none;
    color: #0f6848;
    width: 20%;
}

.sign-wrapper:disabled {
    color: grey;
}

