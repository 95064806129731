.single-order-wrapper {
    background-color: #F7F5EF;
    color: white;
}

.single-order-wrapper > div > div > div {
    position: unset;
}

.sov-items-container {
    background-color: #8AB7AE;
    margin-top: -5%;
}

.sc-cannot-find {
    text-align: left;
    padding-top: 4%;
    padding-left: 2%;
    font-weight: bold;
    cursor: pointer;
}

.sc-delivery-wrapper {
    width: 100%;
    background-color: #707070;
    border-radius: 5px;
}

.sc-order-total-wrapper {
    margin-top: 10%;
    background-color: white;
    color: #005343;
    border-radius: 5px;
}

.actions-wrapper-with-margin {
    margin-top: 10%;
}

.sc-vendor-info-wrapper {
    margin-top: 10%;
    background-color: white;
    color: #707070;
    padding-bottom: 7%;
    border-radius: 5px;
}

.sc-order-button {
    background-color: white;
    margin-top: 2%;
    padding: 1% 0;
}

.sc-actions-wrapper {
    color: #707070;
    width: 100%;
    background-color: white;
    border-radius: 5px;
}

.sc-search-wrapper {
    background-color: #8AB7AE;
    padding: 10% 1% 5% 5%;
    width: 100%;
}

.sc-product-card-wrapper {
    width: 100%;
}

.sc-product-card {
    padding-top: 2%;
}

.sc-cart-expanded-container {
    min-height: 100%;
}

.sc-box {
    padding-top: 0.65%;
    width: 100%;
}

.sov-last-btn-desktop {
    margin-top: 2%;
    padding: 1%;
    background-color: white;
}

.sov-completed-text {
    font-size: 1.25em;
    color: #707070;
    text-align: center;
    padding: 5% 20%;
}

.sov-completed-text2 {
    font-size: 1.25em;
    color: #707070;
    text-align: center;
    padding: 2% 0%;
}

.sov-modal-header {
    border-bottom: 0;
}

.sov-complete-btn {
    width: 25%;
    border-radius: 50px;
    margin: 5% auto;
    background-color: #019D7F;
    border-color: #019D7F;
}

.sov-add-item-header {
    color: #24303E;
    font-size: 1.25em;
    font-weight: bold;
    width: 90%;
    margin: 0 auto;
}

.sov-add-item-name {
    width: 90%;
    margin: 0 auto;
    color: #24303E;
    padding-bottom: 1%;
}

.sov-add-item-textarea {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #707070;
    border-radius: 5px;
    resize: none;
}

.sov-add-item-textarea:focus {
    outline: none;
}

.sov-add-item {
    width: 90%;
    margin: 0 auto;
    padding-top: 5%;
}

.sov-add-item-label {
    display: inline-block;
    width: 50%;
    color: #24303E;
    font-size: 1.1em;
}

.sov-add-item-unit-select {
    display: inline-block;
    width: 50%;
    text-align: right;
}


.sov-add-item-unit-select select{
    padding-left: 25%;
    height: 30px;
    border: 1px solid #0f6848;
}


.sov-add-item-quantity {
    display: inline-block;
    width: 30%;
    text-align: right;
    float: right;
}

.sov-select-unit {
    float: right;
    text-align: center;
    width: 60%;
    border-radius: 50px;
    color: #24303E;
    background-color: white;
}

.sov-plus-minus {
    color: #019D7F;

}

.sov-quantity {
    padding: 0 20%;
}

.sov-add-item-btn-wrapper {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    padding: 12% 0 7%;
}

.sov-add-item-btn {
    font-size: 1.35em;
    background-color: #019D7F;
    border: none;
    border-radius: 50px;
    width: 90%;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    padding: 2% 0;
}

.sov-add-item-btn:disabled {
    background: grey;
}

.sov-add-item-btn:focus {
    outline: none;
}

.sov-point-select-wrapper {
    border: 1px solid #707070;
    width: 90%;
    margin: 0 auto 5%;
    padding: 3% 6%;
    border-radius: 10px;
    box-shadow: 0 3px 6px #C7C7C7;
    cursor: pointer;
}

.sov-point-select-wrapper:hover {
    background-color: #C0DED8;
}

.selected-point {
    background-color: #C0DED8;
}

.sov-point-name {
    font-size: 1.5em;
    color: #24303E;
    font-weight: bold;
}

.sov-point-address {
    font-size: 1.4em;
    color: #24303E;
}

.sov-comment-textarea-wrapper {
    text-align: center;
}


.sov-comment-textarea-wrapper textarea {
    resize: none;
    border: 2px solid #D8D8D8;
    border-radius: 5px;
    box-shadow: 0 3px 6px #d8d8d8;
    padding: 1% 2%;
}

.sov-comment-buttons-wrapper {
    width: 100%;
    padding: 5% 7% 8%;
}

.sov-comment-cancel {
    background-color: #7C7C7C;
    width: 30%;
    padding: 3%;
    border-radius: 50px;
    color: white;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
}

.sov-comment-save {
    background-color: #019D7F;
    width: 30%;
    padding: 3%;
    border-radius: 50px;
    color: white;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    margin-left: 40%;
    cursor: pointer;
}

.sov-comment-maxlength {
    width: 90%;
    text-align: right;
}


.sov-back-to-top {
    background-color: #525D69;
    color: white;
    padding-bottom: 0.3%;
    cursor: pointer;
}

.sov-back-to-top img {
    width: 1em;
}



@media only screen and (min-width: 998px) {
    .sc-cart-expanded-container {
        display: flex;
    }

    #stick {
        position: sticky;
        top: 11%;
        width: 100%;
        z-index: 900;
    }

    #stick > .sc-cart-wrapper {
        border-bottom: none
    }

    .sc-product-card-wrapper {
        width: 69%;
        padding-left: 1%;
    }

    .sc-product-card {
        padding-top: 1%;
    }

    .sc-box {
        width: 30%;
        padding-right: 1%;
        float: right;
    }

    .sc-search-wrapper {
        padding: 2% 1% 5% 0%;
    }

    .sc-content-after-products {
        display: inline-block;
        width: 30%;
        float: right;
        padding-left: 1%;
    }

    .sc-order-total-wrapper {
        margin-top: 5%;

    }

    .actions-wrapper-with-margin {
        margin-top: 5%;
    }

    .sc-vendor-info-wrapper {
        margin-top: 5%;
    }

    .sov-items-container {
        margin-top: -3.3%;
        width: 98%;
        margin-left: 1%;
    }

    .sov-comment-buttons-wrapper {
        width: 80%;
        margin: 0 auto;
    }

    .sov-comment-cancel {
        width: 35%;
    }

    .sov-comment-save {
        width: 35%;
        margin-left: 30%;
    }

    .sov-comment-maxlength {
        width: 83%;
    }
}