.order-card-products-info {
    padding-top: 2%;
    text-align: right;
    font-size: 0.95em;
    width: 40%;
}


@media screen and (min-width: 998px) {
    .order-card-products-info {
        padding-top: 1%;
        width: 12%;
    }
}