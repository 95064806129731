.np-status-notification-wrapper {
    width: 96%;
    margin: 2% auto;
    background-color: white;
    border-radius: 5px;
    padding: 2% 3%;
    color: #707070;
    text-align: left;
    box-shadow: 3px 3px 6px #D6D6D6;
}

.np-link-order:hover {
    text-decoration: none;
}