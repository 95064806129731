.vp-product-card-wrapper {
    background: white;
    border-radius: 5px;
    width: 100%;
    padding-bottom: 2%;
    margin-bottom: 3% !important;
}

.vp-erp-promo-wrapper {
    width: 100%;
    padding-top: 2%;
}

.vp-product-number {
    width: 20%;
    font-size: 0.7rem;
    float: left;
    text-align: left;
    color: grey;
}

.vp-card-list-body-container {
    margin-top: 1%;
    width: 100%;
    display: flex;
}

.vp-card-list-image {
    margin-top: 1%;
    width: 20%;
}

.vp-product-image {
    max-width: 75px;
    max-height: 75px;
}

.vp-card-list-body {
    width: 70%;
    padding-left: 2%;
}

.vp-product-name {
    font-weight: bold;
    color: #019D7F;
    width: 100%;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1;
    text-align: left;
    padding-top: 3%;
}

.vp-price-selected {
    text-align: left;
    font-size: 0.725rem;
    color: #76757A;
    margin-right: 3%;
    display: inline-block;
    float: left;
}

.vp-pills-wrapper {
    z-index: +2;
    line-height: 1;
    padding: 1% 0 2%;
    display: inline-block;
    text-align: left;
    width: 100%;
}

.vp-availability {
    font-size: 0.625rem;
    text-align: left;
    font-weight: bold;
}

.vp-price-container {
    width: 100%;
    line-height: 1.2;
    text-align: right;
    padding-right: 7%;
    margin-top: -1%;
}

.vp-card-list-add-btn-wrapper {
    width: 95%;
    margin: 0 auto;
    padding-top: 1%;
}


.vp-vendor-promo-wrapper {
    width: 74%;
    display: flex;
    flex-wrap: wrap;
}

.vp-promo-price {
    font-size: 11px;
    color: #B72121;
}

.vp-card-price-and-button-desktop {
    width: 25%;
    padding-top: 2%;
}

.vp-card-list-btn-wrapper-desktop {
    padding-top: 10%;
}

.vp-card-list-promo-text {
    line-height: 1;
    font-size: 0.825rem;
    text-align: left;
    padding-left: 5%;
    font-weight: bold;
    color: #B72121;
    padding-top: 1%;
}

.vp-card-list-delete-icon {
    width: 1em;
    cursor: pointer;
}


.cp-card-list-add-comment {
    color: #019D7F;
    text-decoration: underline;
    text-align: left;
    padding-left: 5%;
    font-weight: bold;
    padding-top: 1%;
}

.c-pointer {
    cursor: pointer;
}

.cp-card-list-add-comment img {
    width: 1em;
    margin-right: 3%;
}

.card-list-comment-text {
    width: 100%;
    text-align: left;
    padding-left: 5%;
    font-style: italic;
    color: #24303E;
    word-break: break-all;
}

.vp-available {
    color: #019D7F;
}

.vp-not-available {
    color: #B72121;
}

@media screen and (min-width: 320px) and (max-width: 359px) {

    .vp-vendor-promo-wrapper {
        padding-left: 12%;
    }
}

@media screen and (min-width: 998px) {
    .vp-product-card-wrapper {
        padding-bottom: 1%;
        margin-bottom: 1% !important;
    }

    .vp-product-number {
        width: 10%;
    }

    .vp-card-list-image {
        width: 10%;
        margin-top: 0;
    }

    .vp-price-container {
        margin-top: 0%;
        padding-right: 8%;
    }

    .vp-product-name {
        -webkit-line-clamp: 5;
        padding-top: 0%;
    }

    .vp-price-selected {
        font-size: 0.9rem;
        margin-right: 0;
        min-width: 30%;
    }

    .vp-pills-wrapper {
        z-index: +2;
        line-height: 1;
        padding: 0% 0 0% 2%;
    }

    .vp-availability {
        font-size: 0.825rem;
    }

    .vp-card-list-btn-wrapper-desktop {
        width: 100%;
        padding-right: 5%;
    }

    .vp-erp-promo-wrapper {
        width: 100%;
        padding-top: 1%;
    }

    .vp-price-container {
        min-height: 27px;
    }

    .vp-card-list-promo-text {
        padding-left: 0%;
        font-size: 0.9em;
    }
    .card-list-comment-text {
        padding-left: 1%;
    }
}


@media screen and (min-width: 1366px) {
    .vp-product-card-wrapper {
        height: unset;
    }

    .vp-card-list-btn-wrapper-desktop {
        padding-top: 1%;
    }

    .vp-card-list-body-container {
        margin-top: 0.5%;
    }

}














