.ov-order-info {
    background-color: white;
    border-radius: 5px;
}

.ov-name-status-wrapper {
    display: flex;
}

.ov-vendor-name {
    width: 60%;
    font-size: 1.375em;
    color: #707070;
    font-weight: bold;
    padding: 3% 0 0 3%;
    text-align: left;
}

.ov-status-wrapper {
    width: 40%;
    padding: 3% 2% 0 0;
    font-size: 0.85em;
}

.ov-add-to-cart-btn-wrapper {
    display: block;
    width: 100%;
    padding: 7% 7% 5%;
    background: #F7F5EF;
}


@media screen and (min-width: 998px) {
    .ov-order-info {
        display: flex;
        margin: 0 2%;
    }

    .ov-name-status-wrapper {
        display: flex;
        width: 100%;
        padding: 1% 0 ;
    }

    .ov-vendor-name {
        width: 60%;
        border-right: 1px solid #d8d8d8;
        padding: 1% 1%;
    }

    .ov-status-wrapper {
        width: 42%;
        font-size: 1em;
        border-right: 1px solid #d8d8d8;
        padding: 2% 1%;
    }

    .ov-add-to-cart-btn-wrapper {
        display: block;
        width: 50%;
        padding: 1% 1%;
        background: none;
    }


    .ov-order-number-and-date-wrapper {
        width: 40%;
        padding: 1%;
    }

    .ov-order-number-and-date-wrapper > div{
        width: 100%;
    }

}