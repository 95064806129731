.sc-delivery-heading {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 6%;
}

.sc-delivery-sale-point-and-address {
    text-align: left;
    padding-left: 3%;
}

.sc-delivery-sale-point-and-address span {
    opacity: 0.5;
    font-size: 1.125em;
}

.sc-delivery-point-name-and-address {
    font-size: 1.375em;
}

.sc-change-sale-point-wrapper {
    padding-top: 3%;
    margin-left: 5%;
    text-align: left;
    text-decoration: underline;
}

.sc-icon-edit {
    width: 1.2em;
}

.sc-change-sale-point {
    color: #A2E8DB;
    font-size: 1em;
    font-weight: bold;
    text-transform: lowercase;
    text-decoration: underline;
    padding-left: 2%;
    cursor: pointer ;
    padding-top: 1%;
}

.sc-order-notes-wrapper {
    padding-top: 6%;
}

.sc-order-note-textarea {
    width: 90%;
    resize: none;
    border-radius: 5px;
    margin-top: 2%;
    margin-bottom: 5%;
}

.sc-order-note {
    text-align: left;
    padding-left: 5%;
    opacity: 0.5;
}
