.body-container {
    padding-left: 5%;
}

.body-container input:focus {
    outline: 2px solid #4E73DF;
}

.ngp-title-wrapper {
    border: 2px solid #707070;;
    color: #707070;;
    font-size: 22px;
    font-weight: bold;
}

.ngp-title-wrapper p {
    padding-top: 41px;
    padding-bottom: 41px;
}

.ngp-back-button {
    border: none;
    background: none;
    margin-top: 1%;
    color: #707070;
}

.ngp-back-button img {
    margin-right: 0.5rem;
    width: 1vw;
}

.ngp-select {
    width: 8vw;
}

.ngp-unit-select {
    width: 6vw;
    font-size: 0.9rem;
}

.measure-select {
    width: 8vw;
}

.category-select {
    width: 16vw;
}

.label {
    width: 11vw;
    text-align: right;
    padding-right: 1%;
}

.sosku-number {
    padding-top: 1vh;
    color: black;
}

.product-name-input {
    width: 60vw;
}

.input-fld {
    border-radius: 0;
}

.btn-primary {
    border-radius: 0;
}

.row {
    margin: 0 !important
}

.ngp-image-preview {
    margin-left: 10vw;
    border: 1px solid grey;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ngp-image-show {
    max-width: 380px;
    max-height: 200px;
}

.input-image {
    display: none;
}

.ngp-add-btn {
    width: 15vw;
}

.weight-input {
    width: 5vw;
    -moz-appearance: textfield;
}

.weight-input::-webkit-outer-spin-button,
.weight-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.ean-input {
    width: 15vw;
}

.searchable-select {
    padding-left: 2%;
    width: 23vw;
}

.bg-grey {
    background-color: grey;
}

.select_search__control {
    border-radius: 0 !important;
    background-image: linear-gradient(to bottom, #F6F6F6, #DEDEDE);
}

.select_search__placeholder{
    color: black !important;
}

.select_search__indicator  {
    color: black !important;
}

.select_search__indicator-separator {
    visibility: hidden;
}