.hp-vendor-card {
    display: flex;
    background-color: white;
    padding: 2% 0;
    border-radius: 5px;
    margin: 1% 0;
}

.v-card-image {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.v-card-image img {
    max-width: 75px;
}

.hp-vendor-card-body {
    width: 75%;
    padding-left: 1%;
}

.v-card-name {
    color: #24303E;
    font-weight: bold;
    text-align: left;
}

.v-card-rate {
    font-size: 0.7em;
    text-align: left;
}

.v-card-description {
    color: #24303E;
    font-size: 0.8em;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
}

@media screen and (min-width: 998px) {
    .hp-vendor-card {
        margin: 1% 1%;
    }

    .hp-vendor-card-body {
        display: flex;
    }

    .v-card-image {
        width: 20%;
        max-height: 140px;
        position: relative;
    }

    .v-card-image img {
        max-width: 144px;
        max-height: 100px;
    }

    .v-card-rate span {
        padding: 0.2em 0;
    }

    .v-card-info-wrapper {
        width: 35%;
    }

    .v-card-name {
        font-size: 1.5em;
        color: #707070;
    }

    .v-card-rate {
        font-size: 1em;
    }

    .v-card-description {
        width: 65%;
        font-size: 1.2em;
        -webkit-line-clamp: 5;
        color: #707070;
    }
}

@media screen and (min-width: 1920px) {
    .v-card-image img {
        max-height: 122px;
    }
}