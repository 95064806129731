.sc-order-btn-wrapper {
    width: 70%;
    padding: 6% 4%;
}

.sc-order-btn {
    border: none;
    background-color: #005343;
    border-radius: 50px;
    padding: 5%;
    color: white;
    width: 100%;
    font-size: 1.188em;
    font-weight: bold;
    text-transform: uppercase;
}

.sc-order-button-desktop-wrapper {
    width: 30%;
    margin: 0 auto;
    padding: 0;

}

.sc-order-button-desktop {
    padding: 1.6% 0;

}