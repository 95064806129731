.vp-container {
    background: #F7F5EF;
}

/* */

.cleardiv {
    height: 58px;
}

.drawer-chev-down {
    width: 1.2em;
}

.line-wrapper {
    border-bottom: 1px solid #a9aab1;
}

.drawer-collapse-content {
    background: white;
}

.categories-toggler {
    padding-top: 3%;
}

.categories-header {
    color: #707070;
    font-size: 1.5em;
}

.close-categories {
    padding-top: 1.5%;
}

.close-image {
    font-size: 1em;
}

.products-section {
    width: 100%;

}

.show-more-btn-wrapper {
    width: 100%;

}


@media screen and (min-width: 768px) {
    .show-more-btn {
        margin-left: 30%;
    }
}


@media screen  and (min-width: 1000px){
    .products-section {
        width: 70%;
        padding-left: 1%;
        margin-left: 1%;
        min-height: 80vh;

    }
    .vp-view-select-container {
        width: 90%;
        scroll-margin-top: 100px;
    }

    .show-more-btn-wrapper {
        float: left;
        margin-top: 1%;
        bottom:0;
        left: 0;
        padding-bottom: 1%;
    }

    .search-input-container {
        width: 32%;
    }
    .show-more-btn {
        margin-left: 16%;
    }

}

@media screen  and (min-width: 1367px){
    .products-section {
        width: 80%;
        margin-left: 0;
    }
    .vp-view-select-container {
        width: 90%;
    }
    .vp-web-min-price {
        position: relative;
        margin-left: 0%;
    }
    .show-more-btn-wrapper {
        float: left;
        margin-top: 1%;
        margin-left: 30%;
    }

    .search-input-container {
        width: 20%;
    }

    .show-more-btn {
        margin-left: 30%;
    }
}












