.vp-add-to-cart-btn {
    border-radius: 50px;
    border: none;
    background-color: #019D7F;
    background-image: url("../../../assets/truck-so.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    width: 100%;
    height: 30px;
}

.vp-add-to-cart-btn:focus {
    outline: none;
}

.vp-up-down-btn:focus {
    outline: none;
}
