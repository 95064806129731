.np-container {
    background-color: #F7F5EF;
    color: #707070;
}

.np-text-wrapper {
    width: 100%;
    text-align: left;
    padding-left: 4%;
}

.np-text {
    font-weight: bold;
}

.np-notification-body {
    width: 100%;
    display: flex;
}

.np-message-wrapper {
    width: 100%;
    padding-left: 2%;
    font-size: 0.875em;

}
.np-body-wrapper {
  min-height: 75vh;
}


.np-ad-image-text {
    width: 96%;
    margin: 3% auto;
    padding: 2%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 3px 6px #D6D6D6;
    text-align: left;
}

.np-image  img{
    max-width: 100%;
}

.np-bolded-text {
    font-weight: bold;
}

@media screen and (min-width: 998px) {
    .np-body-wrapper {
        width: 50%;
        margin: 0 auto;
    }

    .np-ad-image-text {
        margin: 2%;
    }

    .np-ad-message {
        margin: 2%;
    }
}

@media screen and (min-width: 1920px) {
    .np-title-section-wrapper {
        margin-top: 0;
    }
}