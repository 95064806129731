.ov-add-to-cart-btn {
    border: none;
    background-color: #019D7F;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 3% 2%;
    text-transform: uppercase;
}


@media screen and (min-width: 998px) {
    .ov-add-to-cart-btn {
        border: none;
        background-color: #019D7F;
        border-radius: 50px;
        color: white;
        font-weight: bold;
        width: 100%;
        padding: 3% 2%;
        text-transform: uppercase;
    }
}