.custom-order-container {
    margin: 2% 0 0 0;
    width: 100%;
    background-color: white;
    color: #0f6848;
    border-radius: 5px;
    display: flex;
}

.custom-order-delete-icon {
    padding-top: 1%;
    width: 5%;
    text-align: right;
    padding-right: 2%;

}
.custom-order-delete-icon img{
    width: 1em;

}

.custom-order-name {
    width: 80%;
    text-align: left;
    padding: 6% 0% 5% 6%;
    text-transform: uppercase;
    font-weight: bold;
}

.custom-order-info {
    width: 15%;
    padding: 6% 0;
}

.comment-wrap {
    width: 90%;
    text-align: right;
    float: right;
}

@media screen and (min-width: 998px) {
    .custom-order-container {
        margin: 1% 0 0 0;

    }
}