.sc-cart-wrapper {
    width: 100%;
    margin: 0 auto;
    color: white;
    border-bottom: 4px solid #FF9300;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 3px 6px #B0B0B0;
    margin-bottom: 3%;
}

.sc-cart-first-row {
    background-color: #019D7F;
    display: flex;
    padding: 4% 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sc-cart-number {
    font-weight: bold;
    width: 10%;
    float: left;
    padding: 2% 0;
    font-size: 1.6875rem;
    border-right: 1px solid white;
}

.sc-cart-vendor-name {
    width: 50%;
    font-weight: bold;
    font-size: 1.125rem;
    text-align: left;
    padding-left: 4%;

}

.sc-cart-vendor-name a {
    color: white;
}

.sc-cart-vendor-name a:hover {
    text-decoration: none;
}

.sc-cart-items-and-price {
    padding-right: 2%;
    width: 40%;
    text-align: right;
    font-size: 0.825rem;
    border-left: 1px solid white;
}

.sc-cart-items-and-price div {
    padding: 1%;
}

.sc-items-count {
    font-weight: bold;
    font-size: 0.925rem;
}

.sc-cart-price {
    font-weight: bold;
    font-size: 0.925rem;
}

.sc-show-items-button-wrapper {
    color: #019D7F;
    font-size: 1.188em;
    padding: 4% 2% 0;
    text-align: right;

}

.sc-show-items-button-wrapper:hover {
    color: #019D7F;
}

.sc-show-items-button-caret {
    padding: 1%;
}

.sc-show-items-button-caret img {
    width: 0.8em;
    padding-top: 0.5%;
}

@media screen and (min-width: 998px) {

    .sc-cart-number {
        font-size: 2em;
        padding: 0;
        width: 7%;
    }

    .sc-cart-first-row {
        padding: 1% 0;
    }

    .sc-cart-vendor-name {
        font-size: 1.813em;
        width: 80%;
    }

    .sc-cart-items-and-price {
        width: 18%;
        text-align: right;
        font-size: 0.825rem;
        padding-right: 1%;
    }

    .sc-show-items-button-wrapper {
        padding: 1% 0 0;
        font-size: 1.188em;
    }

    .sc-show-items-button-caret img {
        margin-top: -0.5%;
    }

    .sc-cart-wrapper {
        width: 98%;
    }

}
