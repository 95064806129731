
.sc-order-total-heading {
    color: #019D7F;
    font-size: 1.5em;
    font-weight: bold;
    padding: 7% 0 4%;
}

.sc-order-total-items-count {
    text-align: left;
    font-size: 1.375em;
    padding-left: 4%;
}

.sc-order-total-items-count span {
    font-weight: bold;
    padding-left: 1%;
}

.sc-order-total-price {
    width: 90%;
    margin: 0 auto;
    font-size: 1.125em;
    display: flex;
    border-top: 1px solid grey;
    padding-top: 3%;
    color: #019D7F;
}

.sc-total-label {
    width: 50%;
    padding: 0% 1% 0;
    text-align: left;
}

.sc-total-value {
    width: 50%;
    text-align: right;
    padding: 0% 1% 0;
}

.sc-order-vat {
    width: 90%;
    margin: 0 auto;
    font-size: 1.125em;
    display: flex;
    border-bottom: 1px solid grey;
    padding-bottom: 3%;
    color: #019D7F;
}

.sc-order-total-price-vat {
    display: flex;
    width: 90%;
    margin: 0 auto;
    font-size: 1.25em;
    padding-top: 1%;
    font-weight: bold;
}

.sc-total-price-info {
    padding: 4% 5%;
    text-align: left;
    font-style: italic;
}

