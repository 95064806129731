.phone-icon {
    width: 1.2em;
}

.vp-web-delivery {
    border-radius: 5px;
    margin-top: 1%;
    margin-right: 1%;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.info-heading {
    font-size: 1.25em;
    padding: 10px;
    font-weight: bold;
}

.vp-web-working-hours {
    border-radius: 5px;
    margin-top: 1%;
    width: 20%;
    background: white;
}

.vp-web-contacts {
    margin-top: 1%;
    margin-left: 1%;
    width: 20%;
    background: white;
    border-radius: 5px;
    padding: 0 2%;
}

.vp-web-min-price {
    font-weight: bold;
    font-size: 1.25em;
}