.order-card-row-1 {
    display: flex;
    padding: 0 2%;
    width: 100%;
}

.order-number {
    padding: 2% 0 0 0;
    width: 37%;
    text-align: left;
    font-size: 14px;
    color: #24303E;
}




.order-date-sent {
    padding: 2% 2% 0 0;
    width: 63%;
    text-align: right;
    font-size: 14px;
    color: #24303E;
}

.order-date-sent span, .order-number span {
    font-weight: bold;
}


@media screen and (min-width: 998px) {
    .order-card-row-1 {
        display: block;
        text-align: left;
        width: 26%;
        border-right: 1px solid #DBDBDB;
    }
    .order-number {
        padding: 2% 0 0 0;
        width: 100%;
        font-size: 1.125em;
        color: #707070;
        font-weight: bold;
    }

    .order-date-sent {
        padding: 2% 0 0 0;
        width: 100%;
        text-align: left;
        font-size: 1.125em;
        color: #707070;
        font-weight: bold;
    }

}