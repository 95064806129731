.sov-btns-wrapper {
    width: 100%;
    padding-bottom: 2%;
}

.delete-modal-body {
    padding: 10%;
}

.btns {
    width: 35%;
    padding: 2%;
    border-radius: 50px;
}

.sov-delete-heading {
    text-align: center;
    padding: 2% 0 4%;
    font-size: 1.25em;
    font-weight: bold;
    color: #A4A4A4;
}

.sov-delete-vendor-name {
    color: #24303E;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 7%;
}
