

@media screen and (min-width: 998px) {
    .footer-cr {
        width: 55% !important;
    }

    .footer-icons {
        width: 45% !important;
    }

}