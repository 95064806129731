.vov-text-buttons-wrapper {
    display: block;
    width: 98%;
    margin: 1%;
}

.vov-info-cards-wrapper {
    width: 100%;
}

.vov-card-container {
    margin:  1%;
    width: 98%;
}

.vov-labels {
    padding: 0 1%;

}

.vov-labels > div{

    width: max-content;
}

.vov-values {
    padding: 0 1%;
}

.vov-values > div{
    width: max-content;
}

.vov-text {
    width: 100%;
    text-align: center;
}

.vov-buttons {
    width: 100%;
}

.vov-custom-padding {
    padding-left: 8%;
}

.vov-table-wrapper {
    margin: 1%;
}

.m1p {
    margin: 1%;
}

.w-max {
    width: max-content;
}

.vov-icon {
    color: #4E73DF;
    font-size: 3em;
}

.icon-colored-text {
    color: #4E73DF !important;
}

.icon-btn:disabled {
    opacity: 0.4;
}

.vov-comment {
    font-style: italic;
    text-transform: lowercase;
    margin-left: 3%;
}

@media screen and (min-width: 998px) {
    .vov-text-buttons-wrapper {
        display: flex;
    }

    .vov-text {
        width: 50%;
    }

    .vov-buttons {
        width: 60%;
        text-align: right;
    }

    .vov-info-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .vov-card-container {
        width: 48%;
    }

    .w-lg-35 {
        width: 35% !important;
    }

    .w-lg-65 {
        width: 65% !important;
    }
    .w-lg-55 {
        width: 55% !important;
    }

    .vov-icons-wrapper {
        width: 50%;
    }
}