.show-more-btn  {
    border: none;
    background: white;
    border-radius: 30px;
    height: 6vh;
    width: 300px;
    box-shadow: 0 3px 6px #D6D6D6;
    margin:0 auto;
}

.show-more-btn:focus  {
    outline: none;
}