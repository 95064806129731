.sc-add-product-text {
    font-size: 1.375em;
    font-weight: bold;
    text-align: left;
    padding: 0% 0 5% 2%;
}

@media screen and (min-width: 998px) {
    .sc-add-product-text {
        padding-top: 5%;
    }
}