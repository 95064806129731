.App {
  text-align: center;
}

.border-red {
  border: 1px solid red !important;
}

.hidden-vp-text {
  color: red;
  font-size: 0.9rem;
  font-style: italic;
}

.red-dot {
  background-color: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  margin-left: 1.5%;
  margin-top: -1%;
  border: 1px solid white;
}

.no-outline {
  outline: none !important;
}

.no-outline:focus {
   outline: none !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.bg-none > .page-link:hover {
  background: none;
  text-decoration: none;
  opacity: 0.78;
}

.bg-none {
  background: none;
  border: none ;
}

.bg-none:focus {
  outline: none;
}

.c-normal {
  cursor: initial !important;
}

.bg-green {
  background-color: #005945 !important;
}

.bg-so-primary {
  background-color: #F7F5EF;
}

.customer-profile-username .ual-user-name {
  color: #707070 !important;
  font-weight: bold;
}

.customer-profile-username .ual-logout {
  color: #019D7F !important;
  font-weight: bold;
}

.customer-profile-username .ual-email {
  color: #707070 !important;
}

.btn-so-green{
  border: none;
  background-color: #019D7F;
  padding: 1% 8%;
  color: white;
  border-radius: 40px;
}

.cp-form-wrapper {
  background-color: white;
  width: 94%;
  margin: 1% auto;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.text-red {
  color: #B72121;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-80 {
 width: 80%;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.text-black {
  color: black;
}

button:focus {
  outline: none !important;
}

.clear-link-style:hover {
  text-decoration: none;
}


.width-100px {
  width: 100px;
}

.width-50px {
  width: 50px !important;
}

.vertical-align-center {
  vertical-align: inherit !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.margin-l-5 {
  margin-left: 5%;
}

.w-lg-30 {
  width: 30% !important;
}

#product-status-change {
  opacity: 1;
}

.hideArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-num-hide-arrows::-webkit-outer-spin-button,
.input-num-hide-arrows::-webkit-inner-spin-button  {
  -webkit-appearance: none;
  margin: 0;
}

.min-width-30 {
  min-width: 30%;
}

.max-width-500 {
  max-width: 500px;
}

.add-image-icon {
  top: 40%;
  right: 40%;
  color: black;
}

.match-checkbox {
  width: 20px;
  height: 20px;
}

.img-max-75 {
  max-height: 75px;
  max-width: 75px;
}

@media screen and (min-width: 768px) {
  .red-dot {
    margin-left: 1%;
    margin-top: -0.4%;
  }
}


@media screen and (min-width: 998px) {
  .red-dot {
    margin-left: 0.6%;
    margin-top: -0.3%;
  }

  .display-flex-lg {
    display: flex;
  }

  .width-33 {
    width: 33%;
  }

  .cp-form-wrapper {
    width: 50%;
  }
}

@media screen and (min-width: 1920px) {
  .red-dot {
    margin-left: 0.5%;
    margin-top: -0.2%;
  }
}

