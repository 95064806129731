.sc-vendor-info-name {
    color: #005343;
    width: 100%;
    text-align: left;
    padding: 8% 10% 5%;
    font-size: 1.25em;
}

.sc-order-phone-icon {
    width: 1.2em;
    margin-left: 1%;
}

.sc-vendor-info-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10%;
    color: #43AAEC;
    font-size: 1.15em;
    padding-bottom: 5%;
}

.sc-order-vendor-info-accordion > div > div {
    width: 90%;
    margin: 0 auto;
    border-bottom-color: #DBDBDB;
}