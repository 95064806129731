.vp-product-promo {
    float: left;
    font-size: 0.7rem;
    background: #B72121;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0.5%;
    text-transform: uppercase;
    margin: 0.5%;
    max-height: 20px;
}


@media screen and (min-width: 1000px){
    .vp-product-promo {
        font-size: 0.725rem;
        padding-top: 0.2%;
    }
}