.ov-product-card-wrapper {
    background-color: white;
    margin: 1% 2%;
    border-radius: 5px;
}

.ov-product-info-wrapper {
    color: #24303E;
    padding: 0 2%;
    font-weight: bold;
}

.ov-product-info {
    width: 100%;
}

.ov-code {
    width: 100%;
    text-align: left;
    padding: 1% 0 0 2%;
    color: #24303E;
    font-size: 0.825em;
}

.ov-product-name {
    width: 100%;
    color: #005343;
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
    padding: 0 0 0 2%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ov-measure-unit-wrapper {
    width: 21%;
}

.ov-product-measure-units {
    border-right: 1px solid #DBDBDB;
}

.ov-single-price-vat-wrapper {
    width: 32%;
}

.ov-single-price-vat {

}

.ov-quantity-wrapper {
    width: 18%;
}

.ov-product-quantity {
    border-left: 1px solid #DBDBDB;
    font-weight: bold;
    border-right: 1px solid #DBDBDB;
}

.ov-total-vat-price-wrapper {
    width: 30%;
    text-align: right;
    font-weight: bold;
}

.ov-order-comment-wrapper {
    width: 100%;
    text-align: left;
    padding: 0.5% 0 1% 2%;
}

@media screen and (min-width: 998px) {
    .ov-product-card-body {
        display: flex;
    }

    .ov-product-name {
        width: 36.5%;
        padding: 0 0 0 1%;
        -webkit-line-clamp: 2;
        max-height: 3em;
    }

    .ov-product-info-wrapper {
        width: 59%;
    }

    .ov-product-measure-units {
        border-left: 1px solid #d8d8d8;
    }

    .ov-measure-unit-wrapper {
        width: 18%;
    }

    .ov-code {
        padding: 1% 0 0 1%;
    }

    .ov-single-price-vat-wrapper {
        width: 33%;
    }

    .ov-order-comment-wrapper {
        padding-left: 1%;
    }
}