

.sc-search {
    width: 100%;
    background-color: white;
    border: 1px solid #717171;
    border-radius: 5px;
    padding-top: 1%;
}


.sc-search label {
    width: 8%;

}

.sc-search input {
    border: none;
    width: 90%;
}

.sc-search input:focus {
    outline: none;
}
