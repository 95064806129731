.grid-card-container {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}


@media screen and (min-width: 1000px) {
    .vp-products-container {
        min-height: 73vh;
    }
}