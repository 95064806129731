

.ov-confirm-delivery-modal-text {
    padding-top: 10%;
    width: 60%;
    margin: 0 auto;
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
}


.ov-delivery-modal-btn-wrapper {
    width: 100%;
    text-align: center;
    padding: 5% 0;
}


.ov-delivery-modal-btn-not-delivered {
    background-color: #707070;
    border: none;
    border-radius: 50px ;
    color: white;
    text-transform: uppercase;
    padding: 2% 10%;
    width: 60%;
    margin-top: 8%;
}

.ov-delivery-modal-btn-delivered {
    background-color:#019D7F;
    border: none;
    border-radius: 50px ;
    color: white;
    text-transform: uppercase;
    padding: 2% 10%;
    width: 60%;
    margin-top: 8%;
}

@media screen and (min-width: 998px) {
    .ov-confirm-delivery-modal-text {
        width: 50%;
        margin-top: 5%;
    }

    .ov-delivery-modal-btn-wrapper {
        display: flex;
        text-align: center;
        margin-top: 8%;
    }

    .ov-delivery-modal-btn-not-delivered {
        padding: 3% 10%;
        width: 40%;
        margin: 0 auto;
    }

    .ov-delivery-modal-btn-delivered {
        padding: 3% 10%;
        width: 40%;
        margin: 0 auto;
    }

    .ov-confirm-dialog > .modal-content {
        max-width: unset;
        width: 800px;
        margin: 0 auto;
    }

}