.vp-cart-button {
    border: none;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    background: #019D7F;
    width: 100%;
}

.vp-items-in-cart {
    color: white;
    font-weight: bold;
}

.vp-cart-button img {
    height: 1.3em;
}

.vp-total-amount {
    text-align: right;
    color: white;
    font-weight: bold;
}
