.category-level-one[aria-expanded=false] {
    background: url(../../../assets/chevron-right-solid.svg) no-repeat right 5% top 50%;
}

.category-level-one[aria-expanded=true] {
    background: url(../../../assets/chevron-down-solid.svg) no-repeat right 5% top 50%;
}

.category-container p {
    margin-bottom: 0.7rem !important;
}

.category-name {
    color: #505050;
    font-size: 1.35rem;
    padding-left: 3%;
}

.category-name:hover {
    color: #019D7F;
}

.category-level-one {
    line-height: 2;
}

.category-name-colored {
    font-size: 0.9rem;
    color: #505050;
    cursor: pointer;
}

.category-name {
    cursor: pointer;
}

.web-category-name {
    font-size: 0.9rem;
    font-weight: bold;
}


.categories-web {
    width: 18%;
    border-radius: 5px;
}

.selected-category {
    color: #019D7F;
    font-weight: bold;
}

.spanSelect {
    z-index: 23;
}

.category-count {
    font-size: 0.8em;
    text-decoration: none;
}

.focus-underline:hover {

    color: #019D7F;
}

@media screen and (max-width: 1366px) {
    .categories-web {
        width: 26%;
    }
}

@media screen and (max-width: 1000px) {
    .category-level-one-content {
        background: white !important;
    }
}

