.sc-all-carts-wrapper {
    width: 100%;
    background-color: #7C7C7C;
    text-align: left;
    color: white;
    padding: 1% 5%;
    font-weight: bold;

}

.sc-delivery-icon {
    width: 1.2em;
}

.sc-cart-all-carts {
    display: inline-block;
    width: 60%;
}

.sc-all-carts-text {
    padding-left: 4%;
    color: white;
}

.sc-all-carts-price {
    float: right;
    display: inline-block;
}


@media only screen and (min-width: 998px) {

    .sc-all-carts-wrapper {
       font-size: 1.5rem;
        padding: 1% 2%;
    }
}