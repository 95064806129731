.vp-footer-wrapper {
    background: #24303E;
    padding: 5% 1% 0;
}

.vp-footer-links {
    padding-top: 6%;
    padding-left: 3%;
    text-align: left;
    font-size: 0.9rem;
    color: white;
}

.vp-footer-policy-links {
    font-size: 0.875rem;
    color: white;
    text-decoration: underline;
}

.vp-footer-copyright {
    color: white;
    font-size: 0.875rem;
}