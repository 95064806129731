.hp-banner-wrapper img {
    width: 100%;
}

.hp-wrapper {
    background-color: #F7F5EF;;
}

.hp-all-vendors-label {
    width: 100%;
    text-align: left;
    padding: 2% 0 0 5%;
    font-weight: bold;
    text-transform: uppercase;
    color: #707070;
}

.link-to-vendorpage {
    text-decoration: none;
    color: inherit;
}

.link-to-vendorpage:hover {
    text-decoration: none;
    color: inherit;
}

.hp-vendor-card-wrapper {
    min-height: 30vh;
}

@media screen and (min-width: 998px) {
    .hp-all-vendors-label {
        font-size: 1.6em;
    }
}