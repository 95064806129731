.vp-header-wrapper {
    width: 100%;
    background-color: white;
    position: sticky;
    top: 0;
    border-bottom: 5px solid #FF9300;
    z-index: 999;
    padding: 2%;
}


.vp-site-logo {
    width: 4em;
}

.header-search-wrapper {

    width: 55%;
}

.orders-wrapper {
    margin-right: 15%;
}

.notifications-wrapper {
    padding-top: 0%;
    margin-right: 15%;
}

.vp-header-icons-wrapper {
    padding: 0 2% 0 0;
    display: flex;
    justify-content: flex-end;
    width: 40%;
}

.nav-image {
    font-size: 1em;
    color: #fff;
}

.image-notification, .image-user  {
    background-color: #FF9300;
    padding-top: 10%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.past-orders-wrap {
    background-color: #FF9300;
    padding: 8% 5%;
    border-radius: 50px;
    width: 70px;
    height: 30px;
    display: flex;

}

.past-orders {
    font-size: 0.65em;
    line-height: 1;
    color: white;
    font-weight: bold;
}

.vp-header-cart {
    display: none;
}

.header-delivery {
    border: none;
    width: 171px;
    height: 47px;
    border-radius: 50px;
    background: #019D7F;
    box-shadow: 0 3px 6px #D7D7D7;
    color: white;
    font-weight: bold;
}

.header-delivery img {
    width: 3em !important;
}

.header-search {
    width: 90%;
    margin: 0 auto;
    background-color: #F7F5EF;
    border: 1px solid #707070;
    border-radius: 50px;
    padding: 1% 3%;
    text-align: left;
}

.header-search label img {
    opacity: 0.7;
}

.header-search-input {
    width: 85%;
    border: none;
    background-color: #F7F5EF;
}

.header-search-input:focus {
    outline: none;
}

.vp-header-icons-wrapper a {
    text-decoration: none;
}


@media screen and (min-width: 992px) {
    .vp-header-wrapper {
        padding: 1%;
    }

    .vp-site-logo {
        width: 6em;
    }

    .vp-site-logo-wrapper {
        margin-left: 0;
        width: 5%;
        text-align: left;
    }

    .notifications-wrapper {
        margin-right: 5%;
        padding: 0;
    }

    .nav-image {
        font-size: 1.8em;
    }

    .image-notification {
        padding-top: 15%;
        width: 46px;
        height: 46px;
    }

    .image-user {
        padding-top: 15%;
        width: 46px;
        height: 46px;
    }

    .orders-wrapper {
        margin-right: 5%;
        padding: 0;
        position: relative;
    }

    .past-orders-wrap {
        padding: 8% 7%;
        width: 100px;
        height: 46px;
        display: flex;
    }

    .past-orders {
        font-size: 0.8em;
        line-height: 1;
        color: white;
        font-weight: bold;

    }

    .vp-header-cart {
        display: block;
        margin-right: 5%;
    }

    .header-search {
        height: 46px;
        width: 75%;
    }
}


@media screen and (min-width: 1366px) {
    .header-search-input {
        width: 90%;
    }
}

@media screen and (min-width: 1920px) {
    .header-search-input {
        width: 93%;
    }

    .header-search {
        padding: 0.5% 3%;
    }

}