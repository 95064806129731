.date-picker div{
    border-radius: 5px;
    border-color: #d8d8d8;
    line-height: 1.85;
}

.vendor-orders-body {
    background-color: #F1F2F6;
}

.pos-search-wrapper {
    width: 100%;
}
.order-status-select {
    width: 75%;
}

.order-table-button {
    border: none;
    padding: 4px;
    background: none;
    font-size: 8px;
}

.vo-actions {
    display: flex;
    justify-content: space-between;
    padding: 3% 10% 0;
}

@media screen and (min-width: 998px) {
    .pos-search-wrapper {
        width: 40%;
        display: flex;
    }
    .date-picker-wrapper {
        display: flex;
    }
    .date-picker div{

        line-height: 1;
    }

    .status-select-wrapper {
        display: flex;
        width: 20%;
    }

    .status-select {
        width: 100%;
    }
}

@media screen  and (max-width: 992px) {
    #vendor-orders-table {
        border: 0;
    }

    .heading-text {
        font-size: 1.25em;
        padding-top: 1%;
    }

    #vendor-orders-table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

    }

    #vendor-orders-table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #vendor-orders-table td {
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: right;
        font-weight: 700;
        font-size: 1.3em;
    }

    #vendor-orders-table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: 400;
        text-transform: lowercase;
    }

    #vendor-orders-table td:last-child {
        border-bottom: 0;
    }

    .order-table-button {
        border: none;
        padding: 4px;
        text-align: right;
        background: none;
        font-size: .5em;
    }

}