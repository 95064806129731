.actions-wrapper {
    display: flex;
    width: 75%;
}

@media screen and (min-width: 998px) {
    .actions-wrapper {
        display: flex;
        width: 30%;

    }
}