.sc-go-back {
    text-align: left;
    font-size: 1.195rem;
    padding: 4%;
    color: #019D7F;
    font-weight: bold;
    background-color: #F7F5EF;
    width: 100%;
}

.sc-go-back a {
    color: #019D7F;
    text-decoration: none;
}

@media screen  and (min-width: 998px) {
    .sc-go-back {
        padding: 1% 2%;
    }
}