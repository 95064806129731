.drawer-container {
    background: #F7F5EF;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    overflow-x: hidden;
    padding-top: 30px;
    transition: 0.3s;
}

.opened {
    width: 90%;
}

.hide-scroll {
    overflow: hidden !important;
    height: 100%;
}

.closed {
    width: 0;
}

.opened-desktop {
    width: 25%;
}

.mdl-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}