
.op-status-dropdown {
    width: 40%;
    margin-left: 55%;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}


.order-status-menu {
    text-align: right;
    width: 100%;

}

.op-orders-statuses {
    display: flex;
    padding: 7% 0;

}


.op-orders-statuses:hover {
    background: none ;
}


.op-orders-statuses-icon {
    width: 10%;
    padding-left: 5%;

}

.op-orders-statuses-icon img {
    width: 1em;
}

.op-orders-statuses-name {
    text-align: left;
    margin-left: 10%;
    width: 70%;
    z-index: -1;
    padding-bottom: 5%;
    border-bottom: 1px solid #D8D8D8;
}

.op-orders-statuses-name:hover {
    z-index: 99 !important;
    background-color: orange !important;
}


@media screen and (min-width: 998px) {
    .op-status-dropdown {
        width: 20%;
        margin-right: 2%;
        margin-bottom: 1%;
        font-size: 1.2em;
    }
    .op-orders-statuses-name {
        padding-bottom: 7%;
    }
    .op-orders-statuses {
        font-size: 1.2em;
        padding: 2% 0;
    }
}

@media screen and (min-width: 1367px) {

    .op-orders-statuses-name {
        padding-bottom: 3%;
    }
}